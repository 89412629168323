var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"second-opinion"}},[_c('list-template',_vm._b({on:{"fetch:data":_vm.fetchData,"delete:confirm":_vm.deleteConfirm},scopedSlots:_vm._u([{key:"create-section",fn:function(){return [(_vm.userData.role === 'PHYSICIAN')?_c('a',{staticClass:"mr-3 mt-2 anchor-link",staticStyle:{"font-size":"13px"},attrs:{"href":"javascript:void(0)"},on:{"click":_vm.openSecondOpinionModal}},[_vm._v(" Add Second Opinion")]):_vm._e()]},proxy:true},{key:"search-section",fn:function(){return [_c('b-form-input',{attrs:{"size":"sm","name":"search","placeholder":"Search by Patient ID"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchPatient.apply(null, arguments)}},model:{value:(_vm.searchvalue),callback:function ($$v) {_vm.searchvalue=$$v},expression:"searchvalue"}}),_c('b-input-group-append',[_c('button',{staticClass:"btn btn-sm btn-pompe-secondary",attrs:{"id":"searchBtn"},on:{"click":function($event){$event.preventDefault();return _vm.searchPatient.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"search","aria-hidden":"true"}})],1),(_vm.searchvalue)?_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.resetSearch.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"scale":"2","icon":"x","aria-hidden":"true"}})],1):_vm._e()],1)]},proxy:true},{key:"custom-cell-patientId",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"anchor-link",attrs:{"to":{
          name: 'patientOverview',
          params: {
            tab: 'patientOverview',
            id: row.item.transientPatient.id
          }
        }}},[_vm._v(" "+_vm._s(row.item.transientPatient.ref)+" ")])]}},{key:"custom-cell-yearOfBirth",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.item.transientPatient.yearOfBirth)+" ")]}},{key:"custom-cell-patientGender",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.item.transientPatient.patientGender)+" ")]}},{key:"actions",fn:function(ref){
        var row = ref.row;
return [_c('dropdown-table-action',{attrs:{"items":_vm.config.tableAction,"row":row}})]}}])},'list-template',_vm.config,false)),_c('modal-template',_vm._b({on:{"modal:on-close":_vm.closeSecondOpinionModal},scopedSlots:_vm._u([{key:"modal-body",fn:function(){return [(!_vm.selectedPatient)?_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.patientList,"type":"text","name":"patient","placeholder":"Select Patient","size":"sm"},model:{value:(_vm.selectedPatient),callback:function ($$v) {_vm.selectedPatient=$$v},expression:"selectedPatient"}})],1):_vm._e(),(_vm.selectedPatient)?_c('table',{staticStyle:{"font-size":"14px"}},[_c('tr',[_c('td',[_vm._v("Patient ID")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(_vm.selectedPatient))])]),_c('tr',[_c('td',[_vm._v("Physician")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(_vm.userData.lastName)+", "+_vm._s(_vm.userData.firstName))])])]):_vm._e(),(_vm.selectedPatient)?_c('br'):_vm._e(),(_vm.selectedPatient)?_c('b-form-group',[_c('label',[_vm._v(" Patient's Second Opinion")]),_c('b-form-textarea',{staticClass:"form-control form-control-sm",staticStyle:{"height":"200px"},attrs:{"id":"opinion","name":"opinion","placeholder":"Second Opinion","size":"sm"},model:{value:(_vm.secondOpinionText),callback:function ($$v) {_vm.secondOpinionText=$$v},expression:"secondOpinionText"}})],1):_vm._e()]},proxy:true},{key:"modal-footer-custom",fn:function(){return [_c('button',{staticClass:"btn btn-sm btn-pompe-primary",attrs:{"disabled":!_vm.secondOpinionText},on:{"click":function($event){$event.preventDefault();return _vm.submitOpinion.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.config.modal.opinion.state !== "edit" ? "Submit" : "Update")+" ")])]},proxy:true}])},'modal-template',_vm.config.modal.opinion,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }