export const  columns = [
  {
    key: "ref",
    label: "Patient ID",
  },
  {
    key: "physicianName",
    label: "Physician Name",
  },
  {
    key: '2ndOpinionNames',
    label: '2nd Opinion Physician'
  },
  {
    key: "yearOfBirth",
    label: "Year of Birth",
  },
  {
    key: "patientGender",
    label: "Patient Gender",
  },
  {
    key: "healthInsurance",
    label: "Health Insurance",
  },
  {
    key: "dateOfLastAssessment",
    label: "Date of Last Assessment",
  },
  {
    key: "dateOfLastVisit",
    label: "Date of Last Visit",
  },
  {
    key: "actions",
    label: "Actions",
  },
];
