<template>
  <div id="home">
    <custom-breadcrumb class="mt-3" :items="breadcrumbs" />

    <b-tabs class="mt-3">
      <b-tab
        v-for="(item, idx) in tabs"
        :key="idx"
        :id="item.page"
        :title="item.label"
        :active="item.page === selectedTab"
        @click="selectTab(item.page)"
      />
      <br />
      <component
        v-if="!showForm"
        :is="selectedTab"
        :userData="userData"
      />
    </b-tabs>

    <router-view v-if="showForm" :userData="userData"/>
  </div>
</template>

<script>
import summaryPatientView from "./Sections/Patients/List.vue";
import treatingPhysicians from "./Sections/Physicians/List.vue";
import secondopinions from "./Sections/PatientFileSecondOpinion/List.vue";
import dataManager from "./Sections/DataManager/List.vue";
import hospitals from "./Sections/HospitalManagement/List.vue"

import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  components: {
    summaryPatientView,
    treatingPhysicians,
    secondopinions,
    dataManager,
    hospitals
  },
  data: () => {
    return {
      loading: false,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
      breadcrumbs: []
    };
  },
  computed: {
    ...mapState({
      tabs: (state) => state.homeOverview.tabs,
      selectedTab: (state) => state.homeOverview.selectedTab,
    }),
    ...mapGetters({
      userData: 'general/getUserData'
    }),
    showForm() {
      return this.$route.path.includes('create') || this.$route.path.includes('edit')
    },
  },
  watch: {
    userData: {
      handler(val) {
        if (val.role !== 'ADMIN') this.setHiddenTab('hospitals')
        if (val.role === 'PHYSICIAN') {
          this.setHiddenTab('dataManager')
          this.setHiddenTab('treatingPhysicians')
        }
        this.reconstructTab();
      },
      deep: true,
    }
  },
  mounted() {
    this.selectTab(this.$route.params.tab);
  },
  methods: {
    ...mapMutations({
      setSelectedTab: "homeOverview/setSelectedTab",
      setTableData: "homeOverview/setTableData",
      setNavs: "homeOverview/setNavs",
      reconstructTab: "homeOverview/reconstructTab",
      setHiddenTab: "homeOverview/setHiddenTab"
    }),
    constructBreadcrumb() {
      this.breadcrumbs = [
        {
          key: 'home',
          text: 'Home',
          action: () => window.location.href = '/web'
        }
      ];

      this.breadcrumbs = [
        ...this.breadcrumbs,
        { ...this.getCurrentTabJson(this.$route.params.tab) }
      ]
    },
    getCurrentTabJson(tab) {
      switch (tab) {
        case 'summaryPatientView':
          return {
            key: 'summary-patients',
            text: 'Summary Patients'
          };
        case 'secondopinions':
          return {
            key: 'secondopinions',
            text: 'Second Opinions'
          };
        case 'treatingPhysicians':
          return {
            key: 'treatingPhysicians',
            text: 'Treating Physicians'
          };
        case 'dataManager':
          return {
            key: 'dataManager',
            text: 'Data Manager'
          };
        case 'hospitals':
          return {
            key: 'hospitals',
            text: 'Hospital Management'
          }
        default:
          return {};
      }
    },
    selectTab(tab) {
      this.setSelectedTab(tab);
      if (this.$route.params.tab !== tab) {
        this.$router.push({
          name: "summary",
          params: { tab },
        });
      }
      this.constructBreadcrumb();
    },
  },
};
</script>

<style lang="scss"></style>
